.button {
  margin: 0 0 1rem;
  padding: 0 1rem;
  background-color: var(--colorSecondaryHover);
  border: none;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4rem;
  border-radius: 2px;
}

.button span {
  color: black;
  font-weight: bold;
  font-size: var(--fontStandard);
}

.button:hover {
  background-color: rgb(187, 187, 187)
}

.good {
  background-color: var(--colorGood);
}

.good:hover {
  background-color: var(--colorGoodSecondary)
}

.bad {
  background-color: var(--colorBad);
}

.bad:hover {
  background-color: var(--colorBadSecondary);
}

.good span,
.bad span {
  color: white;
}

.button:disabled {
  opacity: 0.7;
  background-color: var(--colorSecondaryHover);
}

.button:disabled span {
  color: unset;
}

.button:not([disabled]):hover {
  cursor: pointer;
}

.button:disabled:hover {
  cursor: not-allowed;
}

/* to keep button width the size of the child text when spinner renders*/
.hiddenText {
  visibility: hidden;
}

/* only apply positioning for loading spinner in Button */
.hiddenText + svg {
  position: absolute;
  display: block;
}

.success {
  color: green;
}

.failure {
  color: tomato;
  font-size: (var(--fontLarge));
  font-weight: bold;
}